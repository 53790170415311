//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';
import { i18n } from '@/vueI18n';
import moment from 'moment'

export default {
  name: 'app-admin-view-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      language: getLanguageCode(),
      model: null,
      trips: [
        {
          id: 1,
          name: 'Makkah Trip',
          day: 'Sat',
          date: '30 March 2020',
        },
        {
          id: 2,
          name: 'Makkah Trip',
          day: 'Sat',
          date: '30 March 2020',
        },
        {
          id: 3,
          name: 'Makkah Trip',
          day: 'Sat',
          date: '30 March 2020',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL(){
      return i18n.locale == 'ar'
    },
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({}),
    i18n(key, args) {
      return this.$t(key, args);
    },
    displayName(value){
      return value ? this.isRTL ? value.name.ar : value.name.en : '___'
    },
    displayDate(date){
     return moment(date).format('l');  
    }
  },
};
