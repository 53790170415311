//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getLanguageCode } from '@/i18n';

// import { PlanModel } from '@/modules/plan/plan-model';
import { UserModel } from '@/modules/auth/user-model';
import { FormSchema } from '@/shared/form/form-schema';
import VuePhoneNumberInput from 'vue-phone-number-input';
import i18n from '@/vueI18n';
import Message from '@/shared/message/message';
import firebase from 'firebase/app';
import 'firebase/storage';

const { fields } = UserModel;
const formSchema = new FormSchema([
  // fields.emails,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsIam,
  fields.rolesRequired,
  fields.accountType,
  fields.countryCode,
  fields.branchId,
  fields.avatar
]);

export default {
  name: 'app-admin-form-modal',

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    values: {
        type: Array,
        required: true
    }
  },

  data() {
    return {
      language: getLanguageCode(),
      rules: formSchema.rules(),
      model: null,
      options: [
        'Makkah',
        'Jeddah',
        'Al Madina',
        'Dammam',
      ],
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      branchCity: '',

      branchesOptions: [],
      popupShow: false,
      passwordType: true,
      validPhone: false,
      code: '',
      countryCode: '+966',
      countryName: 'SA',
      isValid: false,
      errorMessage: '',
      phoneLenght: 8,
      phoneMask: '#########',
      TRansObject: {
        countrySelectorLabel: 'Code Country',
        countrySelectorError: 'Choose a country',
        phoneNumberLabel: 'Phone Number',
        example: 'Example :',
      },
      TRansObjectAr: {
        countrySelectorLabel: 'كود البلد',
        countrySelectorError: 'اختر دولة',
        phoneNumberLabel: 'رقم الجوال',
        example: 'مثال :',
      },
      avatar: null,
      recordId: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'admins/form/record',
      findLoading: 'plan/form/findLoading',
      saveLoading: 'admins/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      citiesRows: 'city/list/rows',
      loadingCities: 'city/list/loading',
      placesRows: 'place/list/rows',
      loadingPlaces: 'place/list/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      branchesRows: 'admins/form/branchesRows',
      loadingBranches: 'admins/form/loadingBranches'
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    fields() {
      return fields;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    branches() {
      return this.branchesRows.map((item) => {
        return {
          value: item.id,
          label: item.name[this.language],
        };
      });
    },
    isRTL(){
      return i18n.locale == 'ar'
    }
    // placesList() {
    //   return this.placesRows
    // },
  }, 
  async created() {
    // if (this.isEditing) {
    //   await this.doFind(this.id);
    // } else {
    //   await this.doNew();
    // }
    this.model = formSchema.initialValues(this.record);
    this.model.password = Math.random().toString(36).slice(-8);
    this.model.avatar = {
          publicUrl: undefined,
          name: undefined,
        }

    await this.doFetchCompanyBranches({companyId: this.currentUser.companyId})
    

    // this.model.type = this.fields.type.options[1].value;
    // this.model.name = this.model.name || { en: 'undefined', ar: 'undefined' }
    // this.model.description = this.model.description || { en: 'undefined', ar: 'undefined' }
    // this.model.startCityId = "23701"
  },
  // async mounted() {
  //   await this.doFetchCities()
  // },
  components:{
    VuePhoneNumberInput,
    
  },
  methods: {
    ...mapActions({
      doFetchCities: 'city/list/doFetch',
      doFetchPlaces: 'place/list/doFetch',
      doAdd: 'admins/form/doAdd',
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
      doFetchCompanyBranches: 'admins/form/doFetchCompanyBranches',
      doUpdate: 'admins/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    selectAvatar(value){
       this.avatar = value
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.branchesOptions = this.branches;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.branchesOptions = this.branches.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1,
        );
      });
    },

    doClear() {
      this.selected_cities = [];
    },
    
    doCopyPassword() {
      // let testingCodeToCopy = document.querySelector('#pin-code')
      const id = `#password`
      console.log('ID = ', id);
      let testingCodeToCopy = document.querySelector(id)
      testingCodeToCopy.setAttribute('type', 'text')  
      testingCodeToCopy.select()
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Pin Code was copied ', msg);
        Message.success(this.i18n(`common.passwordWasCopied`));
      } catch (err) {
        Message.error(this.i18n(`common.unableToCopyPassword`));
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()

      // this.indexOfClick = index
      // setTimeout(() => {
      //   this.indexOfClick = -1
      // }, 400);
    },
    async doSubmit(){
      this.model.accountType = "company"
      this.model.roles = ['admin']      
      this.model.countryCode = this.countryCode
      this.model.companyId = this.currentUser.companyId
      const record = await this.doAdd(this.model)
      this.recordId = record.id
      // console.log(record);
      // debugger
      // console.log(record);
      // this.$emit('adminCreated');
      if(!this.avatar){
        this.$emit('adminCreated');
      }
      else{
        this.uploadAvatar(`saryahCompany/admin/${this.recordId}/avatar`, this.avatar)
      }
    },

    async uploadAvatar(path, request) {           
            const ref = firebase.storage().ref();
            const fullPath = `${path}/${request.name}`;       
            const task = ref.child(fullPath).put(request);

            await task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const percent =
                (snapshot.bytesTransferred /
                    snapshot.totalBytes) *
                100;
                console.log('precent', percent);
            },
            (error) => {
                // request.onError(error);
                console.log(error);
            },
            () => {
                task.snapshot.ref
                .getDownloadURL()
                .then(async (url) => {
                    console.log(url);
                    this.model.avatar.publicUrl = url
                    this.model.avatar.name = request.name
                    this.model.id = this.recordId
                    console.log(this.model.avatar);  
                    console.log(this.model);
                    delete this.model.password
                    debugger
                    await this.doUpdate(
                      this.model
                    )
                    this.$emit('adminCreated');
                    // this.uploadLicense(`saryahCompany/admin/${this.recordId}/license`, this.license)
                })
                .catch((error) => {
                    console.log('error', error);
                });
            },
            );
        },

    onPhoneUpdate(data) {
      this.countryCode = '+' + data.countryCallingCode;
      this.countryName = data.countryCode
      this.isValid = data.isValid
      this.errorMessage = ''
      console.log('data = ', data);
      console.log('countryCallingCode', data.countryCallingCode)
      console.log('formattedNumber', data.formattedNumber)
      this.validPhone = data.isValid
      console.log('validPhone = ', this.validPhone);
      // this.validatePhone(data.formattedNumber)
    },


  },
};
