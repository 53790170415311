import { render, staticRenderFns } from "./admin-form-modal.vue?vue&type=template&id=6632a508&scoped=true&"
import script from "./admin-form-modal.vue?vue&type=script&lang=js&"
export * from "./admin-form-modal.vue?vue&type=script&lang=js&"
import style0 from "./admin-form-modal.vue?vue&type=style&index=0&id=6632a508&lang=scss&scoped=true&"
import style1 from "./admin-form-modal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6632a508",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QForm,QInput,QSelect,QItem,QItemSection,QRadio,QItemLabel,QCardActions,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QForm,QInput,QSelect,QItem,QItemSection,QRadio,QItemLabel,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
